<p-dialog header="Compare the Cases" *ngIf="isShow" [(visible)]="isShow">
  <h1>{{PatientName}}</h1>
  <div class="container">
    <div *ngFor="let c of cases">
      <h3>{{c.createTime}}</h3>
      <div class="photo-area">
        <img [src]="getCasePhoto(c)" (click)="openBigPhoto(c)"/>
      </div>
    </div>
  </div>
</p-dialog>
<tudi-case-big-photo [case]="case"></tudi-case-big-photo>
