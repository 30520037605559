import { Component } from '@angular/core';
import { AppService } from '../app.service';
import { MessageService } from 'primeng/api';
import {ActivatedRoute} from "@angular/router";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent {
  account: string = '';
  password: string = '';

  constructor(
    private appService: AppService,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.testing();
    console.log('receive parameters: ');
    this.route.queryParams.subscribe(params => {
      console.log(params);
    });
  }

  testing(): void {
    // this.appService.login('linhuanyu', '123456');
  }

  onLogin(): void {
    this.appService.login(this.account, this.password);
  }
}
