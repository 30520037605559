import { Injectable, isDevMode } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable} from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ContentType, SeverityEnum, ToastMsg } from 'src/model/page';
import { MessageService } from 'primeng/api';
import { JsonLogin, TudiErrorCode, TudiException } from 'src/model/dto';
import { Router } from '@angular/router';
import {environment} from "../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class AppService {
  host = environment.host;
  loginInfo: JsonLogin;
  isBlock = false;

  constructor(
    private http: HttpClient,
    private msgService: MessageService,
    private router: Router
  ) { }

  private parseJSONtoParams(data: any): string {
    const params = new URLSearchParams();
    for (const key in data) {
      if (typeof (data[key]) === 'object') {
        params.append(key, JSON.stringify(data[key]));
      } else {
        params.append(key, data[key]);
      }
    }
    return params.toString();
  }

  post(page: string, op: string, paramTable?: any): Observable<any> {
    const body = this.parseJSONtoParams(paramTable);
    return this.postBody(page, op, body, ContentType.form);
  }

  postWithType<T>(page: string, op: string, paramTable?: any): Observable<T> {
    const body = this.parseJSONtoParams(paramTable);
    return this.postBody(page, op, body, ContentType.form);
  }

  postBody(page: string, op: string, body: string, contentType: ContentType): Observable<any> {
    const url = this.host + '/' + page + '/' + op;
    const headers = new HttpHeaders({
      'Content-Type': contentType,
      Authorization: this.loginInfo? this.loginInfo.token: ''
    });
    const options = { headers };
    return this.http.post(url, body, options).pipe(
      catchError(e => {
        if (e.error && e.error.errorcode) {
          const tudiErr: TudiException = e.error;
          if (tudiErr.errorcode === TudiErrorCode.TudiAuthException) {
            this.router.navigate(['login']);
            this.msgService.add(new ToastMsg(SeverityEnum.error, 'Please login', 'Please login first'));
            return e;
          } else {
            throw e;
          }
        } else {
          this.msgService.add(new ToastMsg(SeverityEnum.error, 'Something went wrong', JSON.stringify(e)));
          throw e;
        }
      })
    );
  }
  postForm(page: string, op: string, paramTable?: any): Observable<any> {
    let formData = new FormData();
    for (const key in paramTable) {
      formData.append(key, paramTable[key]);
    }
    const url = this.host + '/' + page + '/' + op;
    const headers = new HttpHeaders({
      Authorization: this.loginInfo? this.loginInfo.token: ''
    });
    const options = { headers };
    return this.http.post(url, formData, options);
  }

  get(page: string, op: string, paramTable?: any): Observable<any> {
    const body = this.parseJSONtoParams(paramTable);
    const url = this.host + '/' + page + '/' + op + '?' + body;
    const headers = new HttpHeaders({
      Authorization: this.loginInfo? this.loginInfo.token: ''
    });
    const options = { headers };
    return this.http.get(url, options);
  }

  generateGetUrl(page: string, op: string, paramTable?: any): string {
    const body = this.parseJSONtoParams(paramTable);
    const url = this.host + '/' + page + '/' + op + '?' + body;
    return url;
  }

  login(account: string, password: string): void {
    const data = {
      account: account,
      password: password
    };
    this.post('general', 'login', data).subscribe({
      next: (res: JsonLogin) => {
        this.loginInfo = res;
        this.msgService.add(new ToastMsg(SeverityEnum.success, 'Succeed to Login', 'Login as ' + res.account));
        this.router.navigate(['case-list']);
        localStorage.setItem('loginInfo', JSON.stringify(this.loginInfo));
      },
      error: err => {
        if (err.error && err.error.errorcode) {
          const tudiErr: TudiException = err.error;
          this.msgService.add(new ToastMsg(SeverityEnum.error, 'Fail to login', tudiErr.errormessage));
        }
      }
    });
  }

  setBlock(block: boolean): void {
    this.isBlock = block;
  }

  logout(): void {
    localStorage.removeItem('loginInfo');
    this.router.navigate(['login']);
  }
}
