import { Component, OnInit, ViewChild } from '@angular/core';
import { JsonCase, UploadedCase } from 'src/model/dto';
import { ImageUploaderComponent } from '../component/image-uploader/image-uploader.component';
import { MessageService } from 'primeng/api';
import { SeverityEnum, ToastMsg } from 'src/model/page';
import { AppService } from '../app.service';
import {DateUtility} from "../utility/DateUtility";

@Component({
  selector: 'app-user-input',
  templateUrl: './user-input.component.html',
  styleUrls: ['./user-input.component.scss']
})
export class UserInputComponent implements OnInit {
  @ViewChild(ImageUploaderComponent) imageUploader: ImageUploaderComponent;
  inputData: UploadedCase;

  constructor(
    private appService: AppService,
    private msgService: MessageService
  ) { }

  ngOnInit(): void {
    this.reset();
  }

  reset(): void {
    this.inputData = new UploadedCase();
    this.inputData.createTime = DateUtility.toFormatString(new Date(), 'YYYY/MM/DD hh:mm:ss');
    this.imageUploader.reset();
  }

  upload(): void {
    const datetime = new Date(this.inputData.createTime);
    if (isNaN(datetime.getTime())) {
      this.msgService.add(new ToastMsg(SeverityEnum.error, 'Create Time Format Error', 'Please input the correct time, e.g. 2024/10/01 00:00:00'));
      return
    } else {
      this.inputData.createTime = DateUtility.toFormatString(datetime, 'YYYY/MM/DD hh:mm:ss');
    }
    this.msgService.add(new ToastMsg(SeverityEnum.info, 'Start to upload the case', 'Start to upload the case of ' + this.inputData.name));
    if (this.imageUploader.image) {
      this.inputData.photo = this.imageUploader.image;
      this.appService.postForm('data', 'uploadCase', this.inputData).subscribe({
        next: (res: JsonCase) => {
          this.msgService.add(new ToastMsg(SeverityEnum.success, 'Succeed to upload the case', 'Succeed to upload the case of ' + res.name));
        },
        error: (err) => {
          this.appService.setBlock(false);
        }
      });
      this.reset();
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    } else {
      this.msgService.add(new ToastMsg(SeverityEnum.error, 'Fail to upload the case', 'Please take a photo of tongue or upload one.'));
      this.appService.setBlock(false);
    }
  }
}
