import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

// component
import { UserInputComponent } from './user-input/user-input.component';
import { UserReportComponent } from './user-report/user-report.component';
import { ReportComponent } from './component/report/report.component';
import { ShowReportComponent } from './show-report/show-report.component';
import { CameraDialogComponent } from './component/camera-dialog/camera-dialog.component';
import { ImageUploaderComponent } from './component/image-uploader/image-uploader.component';
import { LoginComponent } from './login/login.component';
import { MenuComponent } from './component/menu/menu.component';
import { CaseListComponent } from './case-list/case-list.component';
import { TongueListComponent } from './tongue-list/tongue-list.component';
import { AccountListComponent } from './account-list/account-list.component';
import { PersonalAccountComponent } from './personal-account/personal-account.component';
import { AccountDialogComponent } from './account-list/account-dialog/account-dialog.component';
import { TongueDialogComponent } from './tongue-list/tongue-dialog/tongue-dialog.component';
import { ImageFileSelectorComponent } from './component/image-file-selector/image-file-selector.component';
import { CaseTaggingComponent } from './component/case-tagging/case-tagging.component';
import { GenderPipe } from './utility/gender.pipe';

// primeng
import { FileUploadModule } from 'primeng/fileupload';
import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { ConfirmationService, MessageService } from 'primeng/api';
import { ToastModule } from 'primeng/toast';
import { PanelModule } from 'primeng/panel';
import { CheckboxModule } from 'primeng/checkbox';
import { RadioButtonModule } from 'primeng/radiobutton';
import { DialogModule } from 'primeng/dialog';
import { TableModule } from 'primeng/table';
import { SelectButtonModule } from 'primeng/selectbutton';
import { OrderListModule } from 'primeng/orderlist';
import { CalendarModule } from 'primeng/calendar';
import { BlockUIModule } from 'primeng/blockui';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { ConfirmPopupModule } from 'primeng/confirmpopup';
import { SidebarModule } from 'primeng/sidebar';

// tool
import { WebcamModule } from 'ngx-webcam';
import { TongueLocationSelectorComponent } from './component/tongue-location-selector/tongue-location-selector.component';
import { CaseDialogComponent } from './case-list/case-dialog/case-dialog.component';
import { CaseBigPhotoComponent } from './component/case-big-photo/case-big-photo.component';
import { CaseEditDialogComponent } from './case-list/case-edit-dialog/case-edit-dialog.component';
import { CaseCompareComponent } from './case-list/case-compare/case-compare.component';

@NgModule({
  declarations: [
    AppComponent,
    UserInputComponent,
    UserReportComponent,
    ReportComponent,
    ShowReportComponent,
    CameraDialogComponent,
    ImageUploaderComponent,
    LoginComponent,
    MenuComponent,
    CaseListComponent,
    TongueListComponent,
    AccountListComponent,
    PersonalAccountComponent,
    AccountDialogComponent,
    TongueDialogComponent,
    ImageFileSelectorComponent,
    CaseTaggingComponent,
    GenderPipe,
    TongueLocationSelectorComponent,
    CaseDialogComponent,
    CaseBigPhotoComponent,
    CaseEditDialogComponent,
    CaseCompareComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule, // 一定要有 FormsModule 不然 ngModel 不能用
    ReactiveFormsModule,
    BrowserAnimationsModule,
    ToastModule,
    FileUploadModule,
    ButtonModule,
    InputTextModule,
    PanelModule,
    CheckboxModule,
    RadioButtonModule,
    WebcamModule,
    DialogModule,
    TableModule,
    SelectButtonModule,
    OrderListModule,
    CalendarModule,
    BlockUIModule,
    ProgressSpinnerModule,
    ConfirmPopupModule,
    SidebarModule
  ],
  providers: [
    MessageService,
    ConfirmationService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
