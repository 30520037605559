<tudi-menu></tudi-menu>
<div class="mt-3" *ngIf="mode === 'list'">
  <p-table [columns]="cols" [value]="cases" dataKey="id" responsiveLayout="stack" [breakpoint]="'720px'" [paginator]="true" [rows]="10">
    <ng-template pTemplate="caption">
      <div class="flex gap-3 align-content-center flex-wrap">
        <div class="w-7rem">
          <p-calendar [(ngModel)]="fromDate" dateFormat="yy/mm/dd" [appendTo]="'body'"></p-calendar>
        </div>
        <div>
          <span class="ml-3">~</span>
        </div>
        <div class="w-7rem">
          <p-calendar [(ngModel)]="toDate" dateFormat="yy/mm/dd" [appendTo]="'body'"></p-calendar>
        </div>
        <div>Patient Name: </div>
        <div>
          <input pInputText type="text" [(ngModel)]="name">
        </div>
        <div *ngIf="isShowCompareButton">
          <button pButton label="Compare Cases" (click)="onCompare()"></button>
        </div>
      </div>
      <div class="flex gap-3 mt-3 align-content-center flex-wrap">
        <div *ngIf="isAdmin">
          <p-checkbox [(ngModel)]="onlyMe" [binary]="true"></p-checkbox>
          <span class="ml-3">Only List My Cases</span>
        </div>
        <div>
          <button pButton label="Search" (click)="loadData()"></button>
        </div>
      </div>
    </ng-template>
    <ng-template pTemplate="header" let-columns>
      <tr>
        <th class="table-cell" *ngFor="let col of columns">{{ col.header }}</th>
      </tr>
    </ng-template>
    <ng-template pTemplate="emptymessage" let-columns>
      <tr>
          <td [attr.colspan]="columns.length">
              No cases are found.
          </td>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-row let-cols="columns">
      <tr (click)="onSelect(row)">
        <ng-container *ngFor="let col of cols">
          <ng-container [ngSwitch]="col.field">
            <td class="table-cell" *ngSwitchCase="'doctor'">
              <span class="p-column-title">{{ col.header }}: </span>{{row[col.field].name}}
            </td>
            <td class="table-cell" *ngSwitchCase="'tongues'">
              <span class="p-column-title">{{ col.header }}: </span>{{getTagStr(row[col.field])}}
            </td>
            <td class="table-cell" *ngSwitchDefault><span class="p-column-title">{{ col.header }}: </span>{{row[col.field]}}</td>
          </ng-container>
        </ng-container>
      </tr>
    </ng-template>
  </p-table>
  <tudi-case-dialog *ngIf="selectedCase" [case]="selectedCase" (tagging)="onStartTagging($event)" (save)="onEdit($event)" (delete)="onDelete($event)"></tudi-case-dialog>
</div>
<tudi-case-tagging *ngIf="mode === 'tagging'" [patientCase]="selectedCase" (onUpdate)="onUpdateTags($event)" (onUpdateAndNext)="onUpdateTagsAndNext($event)" (onCancel)="onCancelTags()"></tudi-case-tagging>
<tudi-case-compare-dialog [cases]="compareCases"></tudi-case-compare-dialog>
