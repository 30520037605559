import {Component, Input, ViewChild} from '@angular/core';
import {JsonCase} from "../../../model/dto";
import {CaseBigPhotoComponent} from "../../component/case-big-photo/case-big-photo.component";
import {AppService} from "../../app.service";

@Component({
  selector: 'tudi-case-compare-dialog',
  templateUrl: './case-compare.component.html',
  styleUrls: ['./case-compare.component.scss']
})
export class CaseCompareComponent {
  @ViewChild(CaseBigPhotoComponent) caseBigPhoto: CaseBigPhotoComponent;
  @Input() cases: JsonCase[];
  isShow = false;
  case: JsonCase;

  constructor(
    private appService: AppService,
  ) {}

  get PatientName(): string {
    if (this.cases && this.cases.length > 0) {
      return this.cases[0].name;
    }
  }

  show(): void {
    this.isShow = true;
  }
  getCasePhoto(theCase: JsonCase): string {
    const data = {
      caseId: theCase.id,
      token: theCase.token
    };
    return this.appService.generateGetUrl('data', 'getCaseTongueImage', data);
  }

  openBigPhoto(c: JsonCase): void {
    this.case = c;
    this.caseBigPhoto.show();
  }
}
