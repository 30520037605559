import { Component, ViewChild } from '@angular/core';
import { AppService } from '../app.service';
import { TableColumn } from 'src/model/page';
import { JsonCase, JsonCaseTongue, JsonTongue, MemberRole, TongueLocation, TongueType } from 'src/model/dto';
import { DateUtility } from '../utility/DateUtility';
import { Utility } from '../utility/Utility';
import { CaseDialogComponent } from './case-dialog/case-dialog.component';
import { MessageService } from 'primeng/api';
import { CaseTaggingComponent } from '../component/case-tagging/case-tagging.component';
import {CaseCompareComponent} from "./case-compare/case-compare.component";

@Component({
  selector: 'app-case-list',
  templateUrl: './case-list.component.html',
  styleUrls: ['./case-list.component.scss']
})
export class CaseListComponent {
  @ViewChild(CaseDialogComponent) caseDialog: CaseDialogComponent;
  @ViewChild(CaseTaggingComponent) taggingComponent: CaseTaggingComponent;
  @ViewChild(CaseCompareComponent) caseCompareDialog: CaseCompareComponent;
  cols: TableColumn[];
  fromDate: Date;
  toDate: Date;
  onlyMe: boolean;
  name = '';
  cases: JsonCase[] = [];
  mode = 'list'; // list | tagging
  selectedCase: JsonCase;
  compareCases: JsonCase[] = [];
  isShowCompareButton = false;

  constructor(
    private appService: AppService,
    private msgService: MessageService
  ) {}

  ngOnInit(): void {
    this.fromDate = new Date();
    this.toDate = new Date();
    this.onlyMe = this.isAdmin? false: true;
    this.initCols();
    this.loadData();
  }

  initCols(): void {
    this.cols = [
      {header: 'Name', field: 'name'},
      {header: 'Doctor', field: 'doctor'},
      {header: 'Tags', field: 'tongues'},
      {header: 'Time', field: 'createTime'}
    ];
  }

  get isAdmin(): boolean {
    return this.appService.loginInfo.role === MemberRole.admin;
  }

  loadData(): void {
    const data = {
      fromDate: DateUtility.toDateString(this.fromDate),
      toDate: DateUtility.toDateString(this.toDate),
      onlyMe: this.onlyMe? 1: 0,
      name: this.name
    };
    this.isShowCompareButton = false;
    this.appService.post('data', 'getCases', data).subscribe((res: JsonCase[]) => {
      this.cases = res;
      if (this.name && this.name.length > 0 && this.cases.length > 1) {
        this.isShowCompareButton = true;
      }
    });
  }

  onSelect(selectedCase: JsonCase): void {
    this.selectedCase = selectedCase;
    console.log(this.selectedCase);
    setTimeout(() => {
      console.log(this.caseDialog);
      this.caseDialog.show();
    }, 100);
  }

  onEdit(updatedCase: JsonCase): void {
    const index = this.cases.indexOf(this.selectedCase);
    if (index !== -1) {
      this.cases[index] = updatedCase;
    }
  }

  onStartTagging(updatedCase: JsonCase): void {
    setTimeout(() => {
      this.mode = 'tagging';
      setTimeout(() => {
        this.taggingComponent.reset();
      }, 100);
    }, 100);
  }

  onDelete(updatedCase: JsonCase) : void {
    const index = this.cases.indexOf(this.selectedCase);
    if (index !== -1) {
      this.cases.splice(index, 1);
    }
  }

  onUpdateTags(newCase: JsonCase): void {
    const editingIndex = this.cases.indexOf(this.selectedCase);
    if (editingIndex !== -1) {
      this.cases[editingIndex] = newCase;
      this.selectedCase = newCase;
      this.mode = 'list';
      setTimeout(() => {
        this.caseDialog.show();
      }, 100);
    }
    this.loadData();
  }

  onUpdateTagsAndNext(newCase: JsonCase): void {
    const editingIndex = this.cases.indexOf(this.selectedCase);
    if (editingIndex === -1) {
      this.msgService.add({severity: 'error', summary: 'Error', detail: 'Fail to find the case to update.'});
      return;
    }
    const nextIndex = editingIndex + 1;
    if (nextIndex < this.cases.length) {
      this.selectedCase = this.cases[nextIndex];
      this.onStartTagging(this.selectedCase);
    } else {
      this.msgService.add({severity: 'info', summary: 'Info', detail: 'No more case to tag.'});
      this.onUpdateTags(newCase);
    }
  }

  onCancelTags(): void {
    this.mode = 'list';
    setTimeout(() => {
      this.caseDialog.show();
    }, 100);
  }

  getCasePhoto(theCase: JsonCase): string {
    const data = {
      caseId: theCase.id,
      token: theCase.token
    };
    return this.appService.generateGetUrl('data', 'getCaseTongueImage', data);
  }

  getTagStr(tongues: JsonCaseTongue[]): string {
    return tongues.map(tongue => Utility.toTongueStr(tongue)).join(', ');
  }

  onCompare(): void {
    this.compareCases = this.cases.sort((a, b) => a.createTime > b.createTime? 1: -1);
    this.caseCompareDialog.show();
  }
}
