export class UploadedCase {
  name: string;
  phone: string;
  email: string;
  gender: number;
  doctor: string;
  symptom: string;
  createTime: string;
  photo: any;

  constructor() {
    this.name = '';
    this.phone = '';
    this.email = '';
    this.gender = 2;
    this.doctor = '';
    this.symptom = '';
    this.createTime = '';
    this.photo = null;
  }
}

export class JsonTongue {
  id: number; // 舌象編號
  name: string; // 名稱
  type: number; // 1 = 舌形、2 = 舌色、3 = 舌苔
  pattern: string; // 中醫辨證
  symptomsMale: string; // 男性症狀
  symptomsFemale: string; // 女性症狀
  recommendation: string; // 建議
  displayOrder: number; // 顯示順序，由 1 開始，不同 type 重新排序
  modTime: string; // 最後修改時間
  status: number; // 0 = 停用，1 = 啟用
}

export class JsonTongueOrderList {
  type: number; // 1 = 舌形、2 = 舌色、3 = 舌苔
  ids: number[]; // 舌象編號列表，代表順序
}

export class JsonCaseTongue {
  locations: number[]; // 1 = Center, 2 = Root, 3 = Front, 4 = Tip, 5 = Side
  tongue: JsonTongue; // 標註的舌象
}

export class JsonCase {
  id: number; // 案例編號
  name: string; // 姓名
  email: string; // E-mail
  phone: string; // 電話
  gender: number; // 性別，1 = 男, 2 = 女
  doctor: string; // 醫生姓名
  symptom: string; // 症狀
  createTime: string; // 建立時間
  isSendMail: number; // 是否已送信，0 = 否、1 = 是
  token: string; // 查看案例 token
  tongues: JsonCaseTongue[]; // 標註的舌象列表
}

export class JsonCaseReport {
  id: number; // 案例編號
  name: string; // 姓名
  doctor: string; // 醫生姓名
  symptoms: string[]; // 症狀
  recommendation: string[]; // 建議
  tongues: JsonTongue[]; // 標註的舌象列表
}

export enum MemberRole {
  'admin' = 1,
  'doctor' = 2
}

export class JsonLogin {
  id: number; // 成員編號
  account: string; // 成員帳號
  name: string; // 姓名
  role: number; // 角色
  token: string; // 登入的jwt token
}

export class JsonMember {
  id: number; // 成員編號
  account: string; // 成員帳號
  password: string; // 密碼
  name: string; // 姓名
  role: number; // 角色
  status: number; // 0 = 停用，1 = 啟用
}

export enum TudiErrorCode {
  'TudiInternalErrorException' = 1,
  'TudiAuthException' = 2,
  'TudiLoginException' = 3,
  'TudiBadRequestException' = 4,
  'TudiJsonException' = 7,
  'TudiDataNotFoundException' = 8
}

export class TudiException {
  errorcode: number;
  errormessage: string;
}

export enum TongueType {
  'Shape' = 1,
  'Color' = 2,
  'Coating' = 3
}

export enum TongueLocation {
  'Center' = 1,
  'Root' = 2,
  'Front' = 3,
  'Tip' = 4,
  'Left' = 5,
  'Right' = 6,
}
