<tudi-menu></tudi-menu>
<div class="md:flex block mb-6">
  <div class="md:w-6 w-12">
    <div class="w-full h-17rem mt-3">
      <tudi-image-uploader></tudi-image-uploader>
    </div>
    <div class="w-full p-3 input-component mt-3 h-8rem">
      <label for="symptomInput" class="input-header">Symptom</label>
      <div class="input-body">
        <input type="text" [(ngModel)]="inputData.symptom" id="symptomInput" placeholder="Symptoms">
      </div>
    </div>
  </div>
  <div class="md:w-6 w-12 md:ml-3">
    <div class="w-full input-component mt-3 h-8rem">
      <label for="nameInput" class="input-header">Name</label>
      <div class="input-body">
        <input type="text" [(ngModel)]="inputData.name" id="nameInput" placeholder="Your Name Here (Ex: John Doe)">
      </div>
    </div>
    <div class="w-full input-component mt-3 h-8rem">
      <label class="input-header">Sex</label>
      <div class="radio-area">
        <div class="radio-item">
          <p-radioButton name="sex-group" value="2" label="Female" [(ngModel)]="inputData.gender"></p-radioButton>
        </div>
        <div class="radio-item">
          <p-radioButton name="sex-group" value="1" label="Male" [(ngModel)]="inputData.gender"></p-radioButton>
        </div>
      </div>
    </div>
    <div class="w-full p-3 input-component mt-3 h-8rem">
      <label for="createTimeInput" class="input-header">Create Time</label>
      <div class="input-body">
        <input type="text" [(ngModel)]="inputData.createTime" id="createTimeInput" placeholder="Create Time">
      </div>
    </div>
    <div class="button-component mt-3 h-8rem" (click)="upload()">
      <i class="pi pi-arrow-right"></i>
    </div>
  </div>
</div>
